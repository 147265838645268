import React, { FC, useEffect, useRef } from 'react';
import { animate } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Typography from 'components/atoms/Typography/Typography';

import { StyledCounter, StyledTitleWrapper } from './Counter.styles';

export type CounterProps = {
  title: string;
  countFrom?: number;
  count: number;
  variant?: 'home' | 'standard';
  extraPlus?: boolean;
};

export const Counter: FC<CounterProps> = ({ title, countFrom = 0, count, variant = 'standard', extraPlus = false }) => {
  const counterRef = useRef(null);
  const additionalMark = extraPlus ? '+' : '';
  const isStandard = variant === 'standard';
  const color = isStandard ? 'primary' : 'secondary';

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      const controls = animate(countFrom, count, {
        duration: 2.5,
        onUpdate: (value) => {
          counterRef.current.textContent = `${Math.floor(value)}${additionalMark}`;
        }
      });

      return controls.stop;
    }
  }, [count, extraPlus, countFrom, inView, additionalMark]);

  return (
    <StyledCounter ref={ref}>
      <StyledTitleWrapper>
        <Typography align="center" variant={isStandard ? 'body1' : 'label'} color={color}>
          {title}
        </Typography>
      </StyledTitleWrapper>
      <Typography weight={isStandard ? '800' : '900'} color={color} variant="h1" renderAs="h6" ref={counterRef}>
        {count + additionalMark}
      </Typography>
    </StyledCounter>
  );
};
