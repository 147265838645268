import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledCounter = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
