import React, { FC } from 'react';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Counter } from 'components/Counter/Counter';

import {
  StyledCounterSection,
  StyledInfoContainer,
  StyledInfoBox,
  StyledContentWrapper,
  StyledCountersContainer,
  StyledCountersBox,
  StyledCounters
} from './CountersSection.styles';

export type CountersSectionProps = {
  title: string;
  description: string;
  content?: string;
  counters: Array<{
    id: number;
    title: string;
    count: number;
  }>;
};

export const CountersSection: FC<CountersSectionProps> = ({ title, description, counters }) => (
  <StyledCounterSection>
    <StyledInfoContainer>
      <StyledInfoBox>
        <Container>
          <Typography variant="h1" renderAs="h2">
            {title}
          </Typography>
          <StyledContentWrapper>
            <Typography variant="body1">{description}</Typography>
          </StyledContentWrapper>
        </Container>
      </StyledInfoBox>
    </StyledInfoContainer>
    <StyledCountersContainer>
      <StyledCountersBox>
        <Container>
          <StyledCounters>
            {counters.map(({ title, count, id }) => (
              <Counter key={id} title={title} count={count} variant="home" />
            ))}
          </StyledCounters>
        </Container>
      </StyledCountersBox>
    </StyledCountersContainer>
  </StyledCounterSection>
);
